// Here you can add other styles
.session-booking-card {
  border-bottom: 1px solid black;
}
.rbc-header {
  background: white;
}
.rbc-day-bg {
  background: white;
}
.rbc-off-range-bg {
  background: rgba(200, 200, 200, 0.1);
}

.rbc-toolbar {
  background: white;
  margin: 0px !important;
  padding: 5px 0px;
}
.rbc-row-content {
  position: absolute;
  bottom: 0;
}
// .rbc-event {
//   margin: 0px !important;
//   width: auto !important;
// }

.number-spinner-stepper {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.2);
  border: 1.5px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-list-item {
  color: black;
  width: 116px;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 500;
  letter-spacing: 0.67px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
}

@media (max-width: 441px) {
  .tab-list-item {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tab-list-active {
    &::after {
      bottom: -7px !important;
    }
  }

  .tab-mark {
    bottom: -8px !important;
  }
}

.tab-list-active {
  &::after {
    content: " ";
    width: 100%;
    height: 6px;
    border-radius: 3px;
    background: #1665d8;
    position: absolute;
    bottom: -15px;
    right: 0;
    z-index: 5;
    left: 0;
    transition: 0.5s translateX(0%);
    margin: auto;
  }
}

.tabs {
  .tab-list {
    position: relative;
    .tab-mark {
      width: 100%;
      height: 6px;
      border-bottom: 1px solid black;
      border-radius: 3px;
      background: white;
      position: absolute;
      bottom: -15px;
      z-index: 3;
    }
  }
  .tab-content {
    margin-top: 2em;
  }
}

.tabs-container {
  margin: 2rem auto;
  // padding: 0 2rem;
  p {
    margin-bottom: 3vh;
    color: black;
    font-size: 0.625rem;
    letter-spacing: 0.74px;
    text-align: justify;
    line-height: 2.5;
  }
}

@media (min-width: 992px) {
  .tabs-container {
    // width: 80%;

    p {
      line-height: 1.5rem;
      font-size: 1rem !important;
    }
  }
}

$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669f2;

.google-btn {
  cursor: pointer;
  width: 184px;
  height: 42px;
  background-color: $google-blue;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  .google-icon-wrapper {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: $white;
  }
  .google-icon {
    position: absolute;
    margin-top: 11px;
    margin-left: 11px;
    width: 18px;
    height: 18px;
  }
  .btn-text {
    float: right;
    margin: 11px 11px 0 0;
    color: $white;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-family: "Roboto";
  }
  .btn-text-dc {
    float: right;
    /* margin: 11px 11px 0 0; */
    padding: 11px 5px 11px 5px;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.2px;
    font-family: "Roboto";
  }
  &:hover {
    box-shadow: 0 0 6px $google-blue;
  }
  &:active {
    background: $button-active-blue;
  }
}

.filterbutton {
  padding: 5px;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  background-color: #fff;
}

.activetab,
.filterbutton:active {
  padding: 5px;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  background-color: rgba(0, 99, 168, 0.13);
  color: #005bb9;
}

.ql-container {
  background-color: #fff;
  border-top: unset !important;
}

.ql-editor {
  min-height: 300px;
}

.clear-filter {
  color: #01953a;
  font-size: 12px;
  cursor: pointer;
}

.clear-filter:hover {
  color: #01953a;
  font-size: 12px;
  text-decoration: underline;
}

.ql-snow .ql-color-picker .ql-picker-options {
  padding: 3px 5px;
  width: 172px;
}

.rw-widget > .rw-widget-container {
  height: 40px;
}

.rw-widget-picker > .rw-select {
  white-space: nowrap !important;
  width: 13% !important;
  border-radius: 100px;
}

.rw-widget {
  width: 100%;
}

.rw-input {
  font-size: 1rem !important;
  font-family: "Roboto" !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.compose,
.rw-widget-container {
  border-radius: unset !important;
  border: unset !important;
}

.compose,
.rw-widget-input,
.rw-filter-input {
  box-shadow: unset !important;
  text-align: center;
}

.compose,
.rw-select-bordered {
  border-left: unset !important;
}

.compose,
.rw-widget-container .rw-state-focus,
.rw-state-focus > .rw-widget-container,
.rw-widget-container.rw-state-focus:hover,
.rw-state-focus > .rw-widget-container:hover {
  border-color: unset !important;
  box-shadow: unset !important;
}

.form-control:disabled {
  background-color: white !important;
  opacity: 1;
  color: rgb(170, 170, 170) !important;
}